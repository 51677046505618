// This QR code has the URL: https://cmcmarkets.onelink.me/3oZk/
// It is used in the Mobile Trading flip card

import { cn } from "@/utils/helpers";
import { SVGAttributes } from "react";

const QRAppFlyerSVG = ({
  width = 144,
  height = 144,
  className = "w-36 h-36 shrink-0 basis-6 fill-white stroke-azure-100",
  ...props
}: {
  width?: number;
  height?: number;
  className?: string;
  props?: SVGAttributes<SVGElement>;
}) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="231px"
      height="231px"
      viewBox="0 0 231 231"
      enableBackground="new 0 0 231 231"
      className={cn(className)}
      {...props}
    >
      <rect x="0" y="0" width="231" height="231" fill="rgb(255,255,255)" />
      <g transform="translate(14,14)">
        <g transform="translate(56,0) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(70,0) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(77,0) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(105,0) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(112,0) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(119,0) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(126,0) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(133,0) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(140,0) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(56,7) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(63,7) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(70,7) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(77,7) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(98,7) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(105,7) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(119,7) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(126,7) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(63,14) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(91,14) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(98,14) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(105,14) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(133,14) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(140,14) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(56,21) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(63,21) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(70,21) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(84,21) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(119,21) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(84,28) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(91,28) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(133,28) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(63,35) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(70,35) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(98,35) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(133,35) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(56,42) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(70,42) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(84,42) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(98,42) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(112,42) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(126,42) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(140,42) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(56,49) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(63,49) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(77,49) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(91,49) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(98,49) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(105,49) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(112,49) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(119,49) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(126,49) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(133,49) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(0,56) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(14,56) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(21,56) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(35,56) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(42,56) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(49,56) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(63,56) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(91,56) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(98,56) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(105,56) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(112,56) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(119,56) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(126,56) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(154,56) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(175,56) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(189,56) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(196,56) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(7,63) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(14,63) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(21,63) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(35,63) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(49,63) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(56,63) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(63,63) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(119,63) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(126,63) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(133,63) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(140,63) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(147,63) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(154,63) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(161,63) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(168,63) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(196,63) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(0,70) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(7,70) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(14,70) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(21,70) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(42,70) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(56,70) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(63,70) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(70,70) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(84,70) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(98,70) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(112,70) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(126,70) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(182,70) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(189,70) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(28,77) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(35,77) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(56,77) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(84,77) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(91,77) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(98,77) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(105,77) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(112,77) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(140,77) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(147,77) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(154,77) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(161,77) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(168,77) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(196,77) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(7,84) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(14,84) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(21,84) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(28,84) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(35,84) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(42,84) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(49,84) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(56,84) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(77,84) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(84,84) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(119,84) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(133,84) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(175,84) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(182,84) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(7,91) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(49,91) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(63,91) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(70,91) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(91,91) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(105,91) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(112,91) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(133,91) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(147,91) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(154,91) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(182,91) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(189,91) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(196,91) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(7,98) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(14,98) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(21,98) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(28,98) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(42,98) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(56,98) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(70,98) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(84,98) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(98,98) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(112,98) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(119,98) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(133,98) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(154,98) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(161,98) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(168,98) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(182,98) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(189,98) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(196,98) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(0,105) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(7,105) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(14,105) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(28,105) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(49,105) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(70,105) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(77,105) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(84,105) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(91,105) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(133,105) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(140,105) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(147,105) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(154,105) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(168,105) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(189,105) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(7,112) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(14,112) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(21,112) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(35,112) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(42,112) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(56,112) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(63,112) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(105,112) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(112,112) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(133,112) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(140,112) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(147,112) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(161,112) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(168,112) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(175,112) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(189,112) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(35,119) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(63,119) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(70,119) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(77,119) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(84,119) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(91,119) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(119,119) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(161,119) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(175,119) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(182,119) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(189,119) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(0,126) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(42,126) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(49,126) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(56,126) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(63,126) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(84,126) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(105,126) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(112,126) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(119,126) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(140,126) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(147,126) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(154,126) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(161,126) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(168,126) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(182,126) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(14,133) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(21,133) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(35,133) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(56,133) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(84,133) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(105,133) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(112,133) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(119,133) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(133,133) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(140,133) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(147,133) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(154,133) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(168,133) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(182,133) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(7,140) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(21,140) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(28,140) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(35,140) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(42,140) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(70,140) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(91,140) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(105,140) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(112,140) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(119,140) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(126,140) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(133,140) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(140,140) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(147,140) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(154,140) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(161,140) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(168,140) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(175,140) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(182,140) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(56,147) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(63,147) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(91,147) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(98,147) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(112,147) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(119,147) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(126,147) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(133,147) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(140,147) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(168,147) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(175,147) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(182,147) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(189,147) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(196,147) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(56,154) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(70,154) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(84,154) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(91,154) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(112,154) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(119,154) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(126,154) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(133,154) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(140,154) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(154,154) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(168,154) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(175,154) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(189,154) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(56,161) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(63,161) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(77,161) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(84,161) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(105,161) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(112,161) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(126,161) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(140,161) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(168,161) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(175,161) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(189,161) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(63,168) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(70,168) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(84,168) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(98,168) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(105,168) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(112,168) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(119,168) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(140,168) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(147,168) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(154,168) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(161,168) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(168,168) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(182,168) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(189,168) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(56,175) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(84,175) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(98,175) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(105,175) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(119,175) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(140,175) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(147,175) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(161,175) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(168,175) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(175,175) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(196,175) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(56,182) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(77,182) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(84,182) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(126,182) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(161,182) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(182,182) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(196,182) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(63,189) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(70,189) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(77,189) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(91,189) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(98,189) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(112,189) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(133,189) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(140,189) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(161,189) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(175,189) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(189,189) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(56,196) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(70,196) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(105,196) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(133,196) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(140,196) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(147,196) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(161,196) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(189,196) scale(0.07,0.07)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(0,0) scale(0.49, 0.49)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <g>
              <rect
                x="15"
                y="15"
                style={{ fill: "none" }}
                width="70"
                height="70"
              />
              <path d="M85,0H15H0v15v70v15h15h70h15V85V15V0H85z M85,85H15V15h70V85z" />
            </g>
          </g>
        </g>
        <g transform="translate(154,0) scale(0.49, 0.49)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <g>
              <rect
                x="15"
                y="15"
                style={{ fill: "none" }}
                width="70"
                height="70"
              />
              <path d="M85,0H15H0v15v70v15h15h70h15V85V15V0H85z M85,85H15V15h70V85z" />
            </g>
          </g>
        </g>
        <g transform="translate(0,154) scale(0.49, 0.49)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <g>
              <rect
                x="15"
                y="15"
                style={{ fill: "none" }}
                width="70"
                height="70"
              />
              <path d="M85,0H15H0v15v70v15h15h70h15V85V15V0H85z M85,85H15V15h70V85z" />
            </g>
          </g>
        </g>
        <g transform="translate(14,14) scale(0.21, 0.21)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(168,14) scale(0.21, 0.21)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(14,168) scale(0.21, 0.21)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default QRAppFlyerSVG;
